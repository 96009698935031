import { Controller } from 'stimulus'
import Swiper, {Autoplay, EffectFade} from 'swiper'
Swiper.use([Autoplay, EffectFade])

export default class extends Controller {

  connect() {
    this.initHeroSlider()
  }

  initHeroSlider() {
    new Swiper(this.element.querySelector('.js-heroSlider'), {
      slidesPerView: 1,
      loop: true,
      speed: 1500,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      autoplay: {
        delay: 1500,
        disableOnInteraction: false,
      },
      allowTouchMove: false,
    })
  }

}
